// ---------------
// Custom Variables / Theme
// ---------------

$spacer: 1rem;
$font-size-base: 0.875rem;
$link-decoration: none;

$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: (
    $spacer * 3.5,
  ),
  7: (
    $spacer * 4,
  ),
  8: (
    $spacer * 5.5,
  ),
);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/maps';

@import '../../../../assets/styles/mfx-variables';

$pt-font-family: 'Roboto', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans',
  'Helvetica Neue', 'Icons16', sans-serif;

$pt-intent-primary: $mfx-main;
$pt-intent-success: $mfx-success;
$pt-intent-warning: $mfx-warning;
$pt-intent-danger: $mfx-error;

$pt-link-color: $pt-intent-primary;
$pt-dark-link-color: $pt-intent-primary;

@import '~@blueprintjs/core/src/common/variables';

$button-intents: (
  'primary': (
    $pt-intent-primary,
    $mfx-main--light,
    $mfx-main--light,
  ),
  'success': (
    $pt-intent-success,
    $pt-intent-success,
    $pt-intent-success,
  ),
  'warning': (
    $pt-intent-warning,
    $pt-intent-warning,
    $pt-intent-warning,
  ),
  'danger': (
    $pt-intent-danger,
    $pt-intent-danger,
    $pt-intent-danger,
  ),
);

$mfx-main: #137cbd;
$mfx-main--light: #2b95d6;

// DARK MODE GRAYS
$mfx-gray-1--dark: #bfccd6;
$mfx-gray-2--dark: #708a9b;
$mfx-gray-3--dark: #293742;
$mfx-gray-4--dark: #24313b;
$mfx-gray-5--dark: #202b33;
$mfx-gray-6--dark: #182026;

//LIGHT MODE GRAYS
$mfx-gray-1: #f5f8fa;
$mfx-gray-2: #ebf1f5;
$mfx-gray-3: #708a9b;
$mfx-gray-4: #5c7080;
$mfx-gray-5: #394b59;
$mfx-gray-6: #182026;

$mfx-success: #3dcc91;
$mfx-warning: yellow;
$mfx-error: #f55656;
$mfx-error-2: #ff7373;

$mfx-letter-spacing: 0.5px;

$mfx-scrollbarBG: #cfd8dc;
$mfx-thumbBG: #90a4ae;

$mfx-scrollbarBG--dark: #293742;
$mfx-thumbBG--dark: #708a9b;

$muted-text: #a7b6c2;
$headingWeight: 500;

$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightBold: 500;

$mfx-label-bg--dark: #1c252c;
$mfx-label-bg: #1c252c; // TODO: replace this with the light theme color here

// Option colors
$optionRed--gradient: linear-gradient(to bottom, #f55656, #8b3131);
$optionYellow--gradient: linear-gradient(to bottom, #f7e78e, #cbba5e);
$optionWhite--white--gradient: linear-gradient(to top, #c1c0c0, #ddd);

$optionRed: #f55656;
$optionOrange: #ff9f3e;
$optionYellow: #f7e78e;
$optionGreen: #3dcc91;
$optionWhite: #cdcdcd;

// color used in the checkbox tree lines e.g: mobile sync detail page
$omni-checkbox-line: #3e4e5a;
$selector-bg-color: #171e24;

@mixin centered-layout-page {
  grid-area: form;
  align-self: center;
  justify-self: center;
  height: max-content;
  width: 100%;
  max-width: 465px;
  background: $mfx-gray-5--dark;
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    padding: 30px;
  }

  &__head {
    align-self: center;
    border-top: 3px solid $mfx-main;
    padding-bottom: 25px;

    h3 {
      padding-top: map_get($spacers, 4);
      margin-bottom: 0;
    }
  }

  &__line {
    border-bottom: 1px solid $mfx-gray-6--dark;
    width: 100%;
  }

  &__note {
    height: 60px;
    background: $mfx-gray-3--dark;
    color: $mfx-gray-1--dark;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btngroup {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bp3-button {
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  @include media-breakpoint-up(sm) {
    &__content {
      padding-left: 60px;
      padding-right: 60px;
    }

    &__btngroup {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .bp3-button {
        width: max-content;
      }
    }
  }
}

@mixin grid-layout {
  background-image: url('../../assets/img/login bg.jpg');
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 70px;
  grid-template-areas:
    'form'
    'footer';
  height: 100vh;
}

@mixin public-page-layout {
  display: grid;
  grid-template-columns: 15vw 1fr 15vw;
  grid-template-rows: 1fr 1px 5fr 70px;

  grid-template-areas:
    'heading heading heading'
    'line line line'
    '. content .'
    'footer footer footer';

  height: 100vh;

  &__head {
    grid-area: heading;
    background: $mfx-gray-5--dark;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);

    h3 {
      border-top: 3px solid $mfx-main;
      padding-top: map_get($spacers, 5);
      margin-bottom: 0;
    }
  }

  &__line {
    grid-area: line;
    border-bottom: 1px solid $mfx-gray-5--dark;
  }

  &__content {
    grid-area: content;

    h4 {
      margin-top: 15px;
      border-left: 3px solid $mfx-main;
      padding-left: map_get($spacers, 3);
    }

    .running-text li {
      list-style-type: square;
    }
  }
}

@mixin mfx-form {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 15px;
  margin: 1px;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

@mixin data-info-text {
  display: inline-flex;
  align-items: center;

  .bp3-dark & {
    color: $muted-text;
  }

  &:not(:last-child):after {
    content: '|';
    padding: 0 map_get($spacers, 2);
  }
}

@mixin heading-ribbon($color: $mfx-main, $margin-top: 1px, $height: 15px, $left: 0, $top: auto) {
  content: '';
  left: $left;
  background: $color;
  width: 3px;
  position: absolute;
  margin-top: $margin-top;
  height: $height;
  top: $top;
}

@mixin tabs {
  display: flex;
  flex: 1;
  min-height: 1px;
  flex-direction: column;

  .bp3-tab-panel {
    margin-top: 0;
  }

  .bp3-tab-list {
    display: flex;
    align-items: center;
    padding: 0 18px;
    position: relative;

    border-bottom: 1px solid darken($mfx-gray-2, 5);

    .bp3-dark & {
      border-bottom: 1px solid $mfx-gray-6--dark;
    }

    &:before {
      @include heading-ribbon($mfx-main, 0, 15px, 0, 23px);
    }
  }
}

@mixin tab-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  @include all-caps-text;
}

@mixin data-section-list-item {
  font-size: 13px;
  display: block;
  gap: 10px;
  padding: 15px 18px;
  user-select: none;
  border-bottom: 1px solid darken($mfx-gray-2, 5);
  border-right: 1px solid darken($mfx-gray-2, 5);
  position: relative;

  &:before {
    @include heading-ribbon($mfx-main, 0, 15px, 0, 0);
    display: none;
  }

  &:hover {
    background: darken($mfx-gray-1, 1);

    &__action-btn {
      display: inline-flex;
    }

    &__action-btn:not(.bp3-active) {
      display: none;
    }
  }

  .bp3-dark & {
    border-bottom: 1px solid $mfx-gray-6--dark;
    border-right: 1px solid $mfx-gray-6--dark;

    &:hover {
      background: lighten($mfx-gray-4--dark, 2);
    }
  }

  &__label-left {
    display: inline-block;
    min-width: 90px;
  }

  &__label-right {
    display: inline-block;
    min-width: 70px;
  }

  &__label-right--hidden {
    display: block;
  }

  &__content {
    display: block;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__title-text {
    flex: 1;
    font-size: 14px;
    font-weight: $headingWeight;
    margin-bottom: map_get($spacers, 1);
  }

  &__type {
    writing-mode: horizontal-tb;
    text-align: left;
    text-transform: capitalize;
    text-orientation: mixed;
    font-size: 12px;
  }

  &__info-text {
    @include data-info-text;
  }

  @include media-breakpoint-up(xl) {
    &__content {
      display: grid;
      grid-gap: map_get($spacers, 2);
      grid-template-columns: minmax(0, 1fr) 240px;
      grid-template-rows: minmax(30px, auto) auto;
    }

    &__title {
      grid-column: span 2;
    }

    &__section {
      margin-bottom: 0;
    }
  }
}

@mixin data-section-list-item--active {
  min-width: fit-content;
  width: 100%;

  background: darken($mfx-gray-1, 2);

  &:before {
    display: block;
  }

  &:hover {
    background: darken($mfx-gray-1, 4);
  }

  .bp3-dark & {
    background: lighten($mfx-gray-4--dark, 2);

    &:hover {
      background: lighten($mfx-gray-4--dark, 2);
    }
  }
}

@mixin data-section-list-item2 {
  min-width: 100%;

  border-bottom: 1px solid darken($mfx-gray-2, 5);
  border-right: 1px solid darken($mfx-gray-2, 5);

  &:hover {
    background: darken($mfx-gray-1, 1);

    &__action-btn {
      display: inline-flex;
    }

    &__action-btn:not(.bp3-active) {
      display: none;
    }
  }

  .bp3-dark & {
    border-bottom: 1px solid $mfx-gray-6--dark;
    border-right: 1px solid $mfx-gray-6--dark;

    &:hover {
      background: lighten($mfx-gray-4--dark, 2);
    }
  }
}

@mixin data-section-list-item-row {
  min-width: fit-content;
  display: grid;
  gap: 15px;
  grid-template-columns: 16px 80px minmax(0, 1fr);
  align-items: center;
  padding: 10px 15px;

  position: relative;

  &:before {
    @include heading-ribbon($mfx-main, 0, 100%, 0, 0);
    display: none;
  }

  &__content {
    display: grid;
    grid-template-rows: repeat(2, 50%);
    row-gap: 5px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin-bottom: 0;
    }

    @include entity-list-item-title__link;
  }

  &__attributes {
    display: grid;
    column-gap: 5px;
    width: 100%;
  }

  &__checkbox {
    .bp3-control {
      margin-bottom: 0;
    }
  }
}

@mixin data-section-list-item-tile {
  padding: 15px;

  &__actions {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    @include entity-list-item-title__link;
  }
}

@mixin scroll-content-wrapper {
  position: relative;
  overflow: auto;
  flex: 1;
  min-height: 1px;
}

@mixin absolute-scroll-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
}

@mixin filter-select-form {
  .bp3-button {
    &.empty {
      color: $input-placeholder-color;
    }
  }

  .bp3-button-text {
    width: 100%;
  }

  .bp3-dark & {
    .bp3-button {
      background: rgba(0, 0, 0, 0.3);

      &.empty {
        color: rgba($gray4, 0.6);
      }
    }
  }
}

@mixin checkbox {
  cursor: pointer;
  appearance: none;
  background-color: $mfx-gray-5;
  margin: 0;
  font: inherit;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
  border-radius: 3px;

  .bp3-dark & {
    background: $mfx-gray-5--dark;
  }

  &:checked {
    background-color: $mfx-main;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  }

  &::before {
    content: '';
    width: 20px;
    height: 20px;
  }

  &:disabled {
    cursor: default;
  }

  &:checked::before {
    content: '\2713';
    color: white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@mixin word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

@mixin all-caps-text {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2px;
}

@mixin detail-page-metadata {
  &__infobox {
    display: block;
    margin: 20px 0;
    border-bottom: 1px solid darken($mfx-gray-2, 5);

    .bp3-dark & {
      border-bottom: 1px solid $mfx-gray-6--dark;
    }
  }

  &__title {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &:before {
      @include heading-ribbon($mfx-main, 0, 11px);
    }

    @include all-caps-text;
  }

  &__subtitle {
    @include all-caps-text;
    font-size: 10px;
    margin-top: 10px;
  }

  &__tag {
    padding: 2px 5px;
    border-radius: 2px;
    @include all-caps-text;
    font-size: 10px;

    background: $mfx-gray-2;

    .bp3-dark & {
      color: $mfx-gray-1--dark;
      background: lighten($mfx-gray-3--dark, 4);
    }
  }
}

@mixin timeline-item-layout {
  @include scroll-content-wrapper;

  &__content {
    @include absolute-scroll-content;
  }

  &__item {
    display: grid;
    grid-template-columns: 50px 1fr;
    gap: 15px;
    align-items: center;
  }

  &__item--no-user {
    grid-template-columns: 1fr max-content;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__user {
    font-weight: 500;
  }

  &__description {
    @include word-wrap;
  }

  &__date {
    position: absolute;
    top: -20px;
    left: 0;
    font-weight: $fontWeightRegular;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@mixin text-feedback {
  @include all-caps-text;
  font-size: 12px;
  color: $mfx-gray-3;

  .bp3-dark & {
    color: $mfx-gray-2--dark;
  }
}

@mixin entity-list-item-tile__type {
  @include all-caps-text;
  background: $mfx-label-bg;
  color: $mfx-gray-2;
  z-index: 1;
  border-radius: 3px;
  font-size: 10px;
  padding: 4px 10px;

  .bp3-dark & {
    background: $mfx-label-bg--dark;
    color: $mfx-gray-2--dark;
  }
}

@mixin breadcrumbs--active {
  .breadcrumbs__ancestor__tiles,
  .breadcrumbs__ancestor__list {
    background: linear-gradient(to left, #2e4250, #3c586a);
  }

  .breadcrumbs__ancestor__tiles:before,
  .breadcrumbs__ancestor__list:before {
    border-color: transparent #3c586a transparent transparent;
  }

  .breadcrumbs__child__list,
  .breadcrumbs__child__tiles {
    background: linear-gradient(to right, #2e4250, #3c586a);
  }

  .breadcrumbs__child__list:before,
  .breadcrumbs__child__tiles:before {
    border-color: transparent transparent transparent #3c586a;
  }
}

@mixin breadcrumbs--active-light-theme {
  .breadcrumbs__ancestor__tiles,
  .breadcrumbs__ancestor__list {
    background: linear-gradient(to right, var(--mfx-gray-1), var(--mfx-gray-3));
  }

  .breadcrumbs__ancestor__tiles:before,
  .breadcrumbs__ancestor__list:before {
    border-color: transparent var(--mfx-gray-3) transparent transparent;
  }

  .breadcrumbs__child__list,
  .breadcrumbs__child__tiles {
    background: linear-gradient(to right, var(--mfx-gray-1), var(--mfx-gray-3));
  }

  .breadcrumbs__child__list:before,
  .breadcrumbs__child__tiles:before {
    border-color: transparent transparent transparent var(--mfx-gray-3);
  }
}

[data-mantine-color-scheme='light'] .breadcrumbs__child__list {
  :hover {
    background: none;
  }
  ::before {
    border-color: transparent transparent transparent var(--mfx-gray-3);
  }
  background: linear-gradient(to right, var(--mfx-gray-1), var(--mfx-gray-3));
}

@mixin data-section-show-after-step($step) {
  @for $_ from $step through 9 {
    .size-#{$_} & {
      display: block;
    }
  }

  .size-xxl & {
    display: block;
  }
}

@mixin entity-list-item__link {
  a {
    color: inherit;
  }
}

@mixin entity-list-item-title__link {
  a {
    color: inherit !important;
  }

  a:hover {
    color: $mfx-main !important;
  }
}

@mixin entity-list__access-level-bar($color) {
  &:before {
    @include heading-ribbon($color, 2px, 16px);
  }

  .entity-list-item--active & {
    &:before {
      @include heading-ribbon($color, 0, 100%, 0, 0);
    }
  }
}

@mixin section-header__access-level-bar($color) {
  h3 {
    &:before {
      @include heading-ribbon($color, 3px);
    }
  }
}

@mixin entity-tile__access-bar--active($color) {
  &:before {
    @include heading-ribbon($color, 0.5px, calc(100% - 1.5px), 0, 0);
  }
}

@mixin entity-tile__access-bar($color) {
  &:before {
    @include heading-ribbon($color, 1px, 16px, 0, 20px);
  }

  .product-list-item-tile--active & {
    @include entity-tile__access-bar--active($color);
  }

  .asset-list-item-tile--active & {
    @include entity-tile__access-bar--active($color);
  }
}

@mixin entity-detail-transparent-content {
  background-color: transparentize(#fff, 0.2);

  .bp3-dark & {
    background-color: transparent;
  }
}

@mixin entity-detail-transparent {
  background-color: transparentize(#fff, 0.2);

  .bp3-dark & {
    background-color: transparentize($mfx-gray-5--dark, 0.2);
  }

  .data-section,
  .data-section__pagination-container,
  .data-section-sidebar,
  .data-section-sidebar__tabs,
  .section-header--use-background {
    @include entity-detail-transparent-content;
  }
}

@mixin list-title-truncate {
  display: flex;
  width: calc(100% - 15px);

  a {
    flex: 1 1 100%;
    min-width: 0;
    max-width: max-content;
  }
}

.form-multi-select,
.form-select {
  @include filter-select-form;

  .bp3-button {
    padding: 2px 10px;
  }

  .bp3-button-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .empty {
    justify-content: end;
  }

  .mantine-PillGroup-group {
    .entity-search-list-item {
      &:before {
        left: 1.5px;
        top: 15px;
      }
    }
  }

  span.bp3-popover-target {
    width: 100%;
  }

  .bp3-tag-input {
    padding-right: 5px;
  }

  .product-search-item__ancestry-wrap {
    display: none;
  }

  .bp3-menu-item:hover {
    background-color: inherit;
  }

  .mantine-PillGroup-group {
    max-height: 80px;
    overflow-y: auto;
  }
}

.form-multi-select {
  .mantine-Input-input {
    &[data-disabled] {
      background: rgba(57, 75, 89, 0.5);
      box-shadow: none;
      color: rgba(167, 182, 194, 0.6);
    }
  }

  .mantine-PillsInputField-field {
    width: 100%;
    &:focus {
      outline: none;
    }
    &:placeholder-shown {
      text-overflow: ellipsis;
    }
  }
}

[data-mantine-color-scheme='light'] .form-select {
  background: var(--mfx-gray-2);
  .form-select__button {
    background-image: none;
    background-color: var(--mfx-gray-2);
  }
}

.mantine-Select-input {
  text-overflow: ellipsis;
}

.form-multi-select--active {
  .mantine-PillsInput-input {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3),
      inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4);
  }
}
.thumbnail__background {
  background: $mfx-gray-3;
}

.form-select-inline__container {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.form-multi-select-group--inline {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.bp3-form-group {
  min-width: 1px;
}

.form-multi-select-wrap--fit-in-parent {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: unset;
  margin-bottom: 0 !important;
  max-height: unset;
  .bp3-form-group {
    height: 100%;
    margin: 1px;
  }

  .bp3-form-content {
    flex: 1;
    overflow: auto;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    padding: 1px;
  }

  .bp3-popover-target {
    padding: 1px;
  }
  .bp3-multi-select,
  .bp3-popover-target,
  .bp3-popover-target > div,
  .mantine-Input-wrapper,
  .mantine-PillGroup-group {
    height: 100% !important;
  }

  &.form-multi-select--large {
    .bp3-form-content {
      grid-template-rows: minmax(0, max-content) auto;
      row-gap: 5px;
    }
  }
}

.bp3-tag.bp3-large {
  padding: 0px 10px;
}

.form-tree-select .bp3-tag-input-values {
  // fix to wrap tags and frow vertically and not horizontally
  width: 0;
}

.bp3-multi-select-tag-input-input {
  font-size: 14px;
  grid-column: 1/2;
  grid-row: 1/2;
}

.form-tree-select > .bp3-popover-target {
  width: 100%;
}

.react-checkbox-tree > ol:first-of-type {
  max-height: 300px;
  padding-top: 0;
  overflow-y: auto;
}
.react-checkbox-tree > ol > li.form-tree-select-option:last-of-type {
  margin-bottom: 6px;
}
.react-checkbox-tree > ol > li.form-tree-select-option:first-of-type {
  margin-top: 6px;
}
.react-checkbox-tree > ol > li {
  padding: 4px;
}

.form-tree-select-option {
  min-width: 100%;
  padding: 4px;
  overflow: hidden;

  label {
    display: flex;
    align-items: center;
    justify-content: left;
    min-width: 100%;
  }

  input[type='checkbox'] {
    @include checkbox;
  }
}

.form-multi-select-portal,
.form-remote-select-popover__portal {
  .mantine-Popover-dropdown {
    max-height: 300px;
    max-width: 400px;
    min-width: 250px !important;
    @media (min-height: 1200px) {
      max-height: 400px;
    }
  }
  .mantine-Popover-dropdown:has(.ancestry-dropdown__sticky) {
    max-height: 300px;
    margin-bottom: 0;
    padding: 0;
    @include media-breakpoint-up(lg) {
      width: 460px !important;
      max-width: none !important;
      max-height: none;
    }

    @media (min-width: 992px) {
      .mantine-Popover-dropdown {
        width: 460px !important;
        max-width: none !important;
      }
    }
  }

  .entity-search-list-item {
    &:before {
      left: 0px;
      top: 24px;
    }
  }
  .mantine-Combobox-option:hover {
    background: rgba(115, 134, 148, 0.1);
    color: var(--mfx-gray-1--dark);
  }

  .mantine-Combobox-options:has(.ancestry-dropdown__sticky) {
    max-height: 300px !important;
    @media (min-height: 1200px) {
      max-height: 400px !important;
    }
  }

  .mantine-Combobox-options {
    max-height: calc(12.5rem * var(--mantine-scale));
    background: var(--mfx-gray-7);
    @media (min-height: 1200px) {
      max-height: 400px;
    }
  }

  .ancestry-dropdown {
    padding-top: 0;
    padding-left: 0;
    margin-top: 1px;

    .entity-search-list-item {
      margin-left: 5px;
    }
  }
}
[data-mantine-color-scheme='light'] {
  .form-multi-select-portal {
    .mantine-Menu-item.active {
      background-color: var(--mfx-gray-2);
    }
    .mantine-Combobox-dropdown {
      .entity-search-list-item {
        background-color: var(--mfx-gray-2);
      }
    }
  }
  .form-multi-select-portal .mantine-Combobox-options {
    background: var(--mfx-gray-2);
  }
}

[data-mantine-color-scheme='light'] {
  .form-tree-select-option input[type='checkbox'] {
    background: var(--mfx-gray-2);
  }
  .form-tree-select-option input[type='checkbox']:checked {
    background-color: var(--mfx-main);
  }
}

.form-multi-select-portal--large {
  .bp3-multi-select-popover {
    position: absolute;
    top: 50px;
  }

  .mantine-Combobox-option:hover {
    background: transparent;
    color: inherit;
  }
}

.entity-search-list-item {
  position: relative;
  list-style: none;
}

.form-multi-select--large {
  .bp3-form-content {
    display: grid;
    grid-template-rows: minmax(0, max-content) auto;
    row-gap: 10px;
  }
  .bp3-text-overflow-ellipsis {
    overflow: visible; //this is necessary so that the access level bar shows in the selected tags
  }

  .mantine-PillsInput-input {
    padding-inline-start: 0.7rem;
  }

  .mantine-PillsInputField-field {
    padding: 0 10px;
    line-height: 50px;
    border-radius: 3px;
  }

  .mantine-Input-input {
    box-shadow: none;
    background: rgba(0, 0, 0, 0.3);
  }
  .mantine-Input-wrapper {
    margin: 0;
  }

  .mantine-PillGroup-group {
    display: grid;
    grid-template-columns: minmax(300px, 1fr);
    grid-auto-rows: 50px;
    gap: 7px;
    max-height: unset;
    height: 233px;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.3);
    padding-left: 10px;
    padding-top: 10px;

    .mantine-Pill-root {
      background: var(--mfx-gray-3--dark) !important;
      color: white !important;
      padding: 10px 6px 10px 8px;
      overflow-wrap: break-word;
      height: 50px;
      max-width: 295px;
      line-height: 20px;
      min-height: 30px;
      min-width: 30px;
      justify-content: space-between;
    }

    .mantine-Pill-label {
      font-size: 12px;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .mantine-CloseButton-root:hover {
      background: none;
      opacity: 0.8;
      color: white;
    }
  }

  .mantine-PillGroup-group > li,
  .mantine-PillGroup-group > .entity-search-list-item,
  .mantine-PillGroup-group > .category-selected-tab-item,
  .bp3-tag {
    height: 51px;
    max-width: 300px;
    background-color: $mfx-gray-3--dark;
    font-size: 12px;
    color: white;

    padding: 4px 5px;
    overflow-wrap: break-word;
    margin-bottom: 5px;
    margin-right: 5px;
    line-height: 20px;
    min-height: 30px;
    min-width: 30px;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    & > .bp3-fill {
      line-height: 42px;
    }
  }

  .mantine-PillGroup-group > .category-selected-tab-item {
    margin-left: 0;
    line-height: 14px;
  }

  .bp3-fill,
  li {
    min-width: 0;
    height: 42px;
    list-style: none;
  }

  .bp3-menu-item {
    height: 42px;
    font-size: 13px;
    line-height: 14px;
    padding: 0;

    :hover {
      cursor: default;
    }

    .searchbox__avatar {
      height: 32px;
      width: 32px;
    }

    .searchbox__thumb {
      height: 44px;
      width: 95px;
    }
  }
}
[data-mantine-color-scheme='light'] {
  .form-multi-select--large .bp3-tag {
    background-color: var(--mfx-gray-5);
    .bp3-input-ghost {
      background-color: var(--mfx-gray-3);
    }
  }
  .form-multi-select--large {
    .bp3-input-ghost {
      background-color: var(--mfx-gray-3);
    }
  }
}

.ancestry-dropdown {
  padding-top: 0;
  padding-left: 0;
  margin-top: 1px;
}

.mantine-Combobox-option,
.mantine-PillGroup-group {
  list-style: none;
}

.form-multi-select--large {
  .mantine-PillsInput-root {
    width: calc(100% - 120px);
  }

  .mantine-PillGroup-group > li,
  .mantine-PillGroup-group > .entity-search-list-item,
  .mantine-PillGroup-group > .category-selected-tab-item,
  .bp3-tag {
    max-width: none;
  }
}
[data-mantine-color-scheme='light'] .ancestry-dropdown__sticky {
  background: white;
}

.clear-all__button {
  position: sticky;
}

.bp3-tag-input.bp3-large:empty::before,
.bp3-tag-input.bp3-large > :last-child {
  margin: 5px 5px 5px 0;
}

.mantine-Select-option {
  font-size: 14px;
  font-weight: var(--mantine-font-size-md);

  &:hover,
  &[data-combobox-active='true'] {
    background-color: var(--mfx-primary);
  }
}
[data-mantine-color-scheme='light'] .mantine-Select-option {
  color: black;
  &:hover,
  &[data-combobox-active='true'] {
    background-color: var(--mfx-gray-2);
  }
}

.form-multi-select,
.form-multi-select .form-multi-select--large {
  .form-multi-select__clear-btn {
    margin: auto 0px;
    border-radius: 2px;

    &:not([disabled]) {
      background: transparent;
    }

    &:hover:not([disabled]) {
      background: var(--mfx-gray-5);
    }
  }
}

.form-remote-select-popover__portal {
  .mantine-Combobox-wrapper {
    margin: 5px;
  }
  .mantine-Combobox-search {
    background: var(--input-bg-color);
    width: 100%;
  }
  .bp3-menu-item:hover {
    background-color: inherit;
    color: inherit;
  }
  .mantine-Combobox-option:hover {
    color: unset;
  }
}
.form-remote-select__placeholder {
  button {
    color: #6e7a84;
  }
}
.form-remote-select__selected-label {
  .mantine-Input-input {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.form-multi-select--large__clear-btn {
  background: var(--mfx-gray-7);
  &:hover:not([disabled]),
  &:disabled {
    background: var(--mfx-gray-5);
  }
}

[data-mantine-color-scheme='light'] {
  .form-multi-select--large__clear-btn {
    background: var(--mfx-primary) !important;
  }
}
.tree-select__pill-input {
  --input-padding-inline-end: 0.5rem !important;
  .tree-select__clear-btn {
    &:hover {
      background-color: var(--mfx-gray-5);
      border-radius: 2px;
    }
  }
  .mantine-PillsInputField-field {
    &:focus {
      outline: none;
    }
    cursor: pointer;
  }
}

.form-tree-select__dropdown {
  min-width: 250px;
  .mantine-Tree-root {
    padding-left: 0 !important;
  }
  .mantine-Tree-subtree {
    padding-left: 0;
    .mantine-Tree-node {
      @for $i from 2 through 10 {
        &[data-level='#{$i}'] {
          .mantine-Group-root {
            padding-left: calc(var(--level-offset) * #{$i - 1});
          }
        }
      }
    }
  }
  .mantine-Combobox-option {
    &:hover {
      background: rgba(115, 134, 148, 0.1);
    }
  }
  .mantine-Checkbox-body {
    align-items: center;
  }
}
.tree-select__pill {
  .mantine-Pill-remove {
    color: var(--mantine-color-gray-6);
  }
}

.tree-select__selected-values {
  overflow: auto;
  max-height: 80px;
}
