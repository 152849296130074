.entity-filters {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .filter-select__btn {
    padding: 5px 10px !important;
  }
}
.entity-filters__new-filter {
  padding: 0px 10px 20px 10px;
}

.entity-filters__title {
  h4 {
    font-weight: $fontWeightRegular;
    @include all-caps-text;

    &:before {
      @include heading-ribbon;
      height: 10px;
    }
  }
}
